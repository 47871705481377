import React from 'react'
import "../assets/css/footer.css";
import facebookIcon from "../assets/img/facebookIcon.svg";
import instagraIcon from "../assets/img/instagramIcon.svg";
import youtubeIcon from "../assets/img/youtubeIcon.svg";
import linkidIcon from "../assets/img/linkidIcon.svg";

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-icons-position'>
            <img
                className='facebook-icon'
                src={facebookIcon}
                alt='Facebook icon'
            />
            <img
                className='instagram-icon'
                src={instagraIcon}
                alt='Instagram icon'
            />
            <img
                className='youtube-icon'
                src={youtubeIcon}
                alt='Youtube icon'
            />
            <img
                className='linkid-icon'
                src={linkidIcon}
                alt='LinkID icon'
            />
        </div>
        <div className='footer-content-position'>
            <h1>
            AV. Irarrazaval 1372 - Ñuñoa
            </h1>
            <h1>
            Santiago de Chile - Chile
            </h1>
        </div>
    </div>
  )
}

export default Footer