import React from 'react'
import "../assets/css/smartPanel.css";
import smartPanel from "../assets/img/smartPanel.png";

const SmartPanel = () => {
  return (
    <div className='smart-panel'>
        <h1 className='panel-title'>Panel inteligente</h1>
        <div className='position-smart-panel'>
            <img
                src={smartPanel}
                alt='SmartPanel'
            />
            <div className='position-content-panel'>
                <h1 className='new-fuctions'>
                La seguridad de tu hogar nunca fue tan fácil de administrar.
                </h1>
                <ul>
                    <li>Se comunica con los dispositivos vinculados.</li>
                    <li>Conexión bluetooth a su smartphone.</li>
                    <li>Panel con Sirena y cámara IP incluidos.</li>
                    <li>Alertas de emergencia.</li>
                    <li>Biblioteca de tutoriales.</li>
                    <li>Ofrece una experiencia interactiva.</li>
                </ul>
            </div>
        </div>
    </div>
  )
}

export default SmartPanel