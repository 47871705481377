import React, { useState, useEffect } from 'react'
import { TextSlider } from "./components/TextSlider";
import { Logo } from "./components/Logo";
import { Floating } from "./components/Floating";
import { Slider } from "./components/Slider";
import "./assets/css/App.css";
import SmartPanel from './components/SmartPanel';
import AppSmartSecurity from './components/AppSmartSecurity';
import WhyChooseUs from './components/WhyChooseUs';
import Footer from './components/Footer';
import PoliticasPrivacidad from "./components/PoliticasPrivacidad";

const App = () => {

  const url = window.location.pathname;
  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {url === "/politicasdeprivacidad" ? (
        <PoliticasPrivacidad/>
      ):(
        <>
        {data !== null ? (
          <div className="fullPage">
            <div className="top-bar">
              <TextSlider />
              <Logo />
              <Floating data={data}/>
            </div>
            <div className="slider-position">
              <Slider/>
            </div>
            <div className='smart-panel-position'>
              <SmartPanel/>
            </div>
            <div className='smart-security-position'>
              <AppSmartSecurity data={data} />
            </div>
            <div className='choose-us-position'>
              <WhyChooseUs/>
            </div>
            <div className='footer-position'>
              <Footer/>
            </div>
          </div>
        ):(<></>)}
        </>
      )}
    </div>
  )
}

export default App