import React from 'react'
import logo from "../assets/img/logo.png";
import "../assets/css/topbar.css";

const Topbar = () => {
    return (
        <div className="topbar-container-policies">
          <div className="logo-policies">
            <img src={logo} alt="logo" />
          </div>
        </div>
      );
    };

export default Topbar