import React from 'react'
import "../assets/css/smartSecurity.css";
import smartPanel from "../assets/img/app-smart-security.png";

const AppSmartSecurity = (data) => {
  const phone = data.data.phoneNumber
  return (
    <div className='smart-security'>
        <h1 className='smart-security-title'>App ADT Smart Security</h1>
        <div className='smart-security-img-position'>
          <img
              src={smartPanel}
              alt='app-smart-security'
          />
          <div className='smart-security-position'>
            <h2>
              Controla con la aplicación la seguridad de tu hogar de manera sencilla.
            </h2>
            <p>
              La aplicación de ADT extiende el control de la seguridad de tu hogar o negocio a tu celular.
            </p>
            <br/>
            <p>
            Te permite conectar y desconectar tu alarma inteligente, visualizar lo que ocurre en tu hogar, recibir alertas y controlar todos los dispositivos conectados.
            </p>
            <a href={`tel:${phone}`} style={{ textDecoration: "none" }}>
                <button value="Más información">Más información</button>
            </a>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
    </div>
  )
}

export default AppSmartSecurity