import React, { useState } from "react";
import Typed from "react-typed";
import close from "../assets/img/close.svg";

import "../assets/css/textSlider.css";

export const TextSlider = () => {
  const [topText, setTopText] = useState("top-indications");

  const handlerTopText = () => {
    setTopText("top-indications-no-display");
  };

  return (
    <div className={topText}>
      <Typed
        strings={["30% OFF EN LA MENSUALIDAD", "30% OFF EN LA MENSUALIDAD"]}
        typeSpeed={5}
        backSpeed={5}
        backDelay={4000}
        fadeOut={true}
        fadeOutDelay={500}
        showCursor={false}
        loop
      ></Typed>
      <img src={close} alt="close" onClick={handlerTopText} />
    </div>
  );
};
