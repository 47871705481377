import React from 'react'
import "../assets/css/whyChooseUs.css";
import smartPanel from "../assets/img/familia.png";

const WhyChooseUs = () => {
  return (
    <div className='why-choose-us'>
        <div className='why-choose-us-container'>
            <div className='why-choose-us-content'>
                <h1 className='why-choose-us-title'>
                    ¿Por qué elegir ADT?
                </h1>
                <p>
                Protegemos a tus seres queridos, bienes y hogares. Décadas de experiencia nos avalan como expertos en seguridad residencial y de empresas. Brindamos un servicio de protección integral las 24 horas con la más moderna tecnología y monitoreamos la correcta operación de los sistemas de detección de intrusos de nuestros clientes.
                </p>
            </div>
            <div className='why-choose-us-img-position'>
                <img
                    src={smartPanel}
                    alt='app-smart-security'
                />
            </div>
        </div>
    </div>
  )
}

export default WhyChooseUs